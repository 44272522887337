import { BaseModel } from '@svnl/shared';

export const toolTypeList = {
  fishingMaster: 'fishing-master',
  fishingTogether: 'fishing-together',
  control: 'control',
  message: 'message',
  calendar: 'calendar',
} as const;

export type ToolType = (typeof toolTypeList)[keyof typeof toolTypeList];

export type Tool = BaseModel & {
  type: ToolType;
  imageUrl: {
    normal: string;
  };
};
